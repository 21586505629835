// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/index.css';

// Render the main application component within a Router to enable navigation.
ReactDOM.render(
    <App />
,
  document.getElementById('root')
);
