import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './components/Header';
import Landing from './components/Landing';
// import AboutSection from './components/AboutSection';
// import ProductsSection from './components/ProductsSection';
// import ContactSection from './components/ContactSection';
// import Footer from './components/Footer';

function App() {
  return (
    <Router>
        {/* <Header /> */}
          <Landing></Landing>
        {/* <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/about" element={<AboutSection />} />
          <Route path="/products" element={<ProductsSection />} />
          <Route path="/contact" element={<ContactSection />} />
        </Routes> */}
        {/* <Footer /> */}
    </Router>
  );
}

export default App;
